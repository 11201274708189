// custom typefaces
import "@fontsource/montserrat/variable.css"
import "@fontsource/merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

// 클립보드에 복사하기 위한 함수
const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text)
    .then(() => {
      showCopyAlert(); // 복사 성공 알림 표시
    })
    .catch((error) => {
      console.error('클립보드 복사 실패:', error);
    });
};

// 클릭 이벤트를 처리하는 함수
const handleClick = (event) => {
  const codeBlock = event.target.parentNode.querySelector('pre').innerText;
  copyToClipboard(codeBlock);
};

// 클립보드 복사 성공 알림 표시
const showCopyAlert = () => {
  const copyAlert = document.createElement('div');
  copyAlert.textContent = '코드가 클립보드에 복사되었습니다.';
  copyAlert.classList.add('copy-alert');
  document.body.appendChild(copyAlert);
  setTimeout(() => {
    copyAlert.classList.add('show');
    setTimeout(() => {
      copyAlert.classList.remove('show');
      setTimeout(() => {
        document.body.removeChild(copyAlert);
      }, 500);
    }, 1500);
  }, 100);
};

// 코드 블록에 복사 버튼 추가하는 함수
const addCopyButtonToCodeBlocks = () => {
  document.querySelectorAll('pre').forEach((codeBlock) => {    
    // 코드 블록을 감싸는 div 요소 생성
    const wrapper = document.createElement('div');
    wrapper.style.position = 'relative'; // 상대적 위치 설정
    
    // 코드 블록을 감싸는 div 요소 안에 원래 코드 블록을 넣기
    codeBlock.parentNode.insertBefore(wrapper, codeBlock);
    wrapper.appendChild(codeBlock);

    // 복사 버튼 생성
    const button = document.createElement('button');
    button.setAttribute('type', 'button'); // 버튼 타입 설정    
    button.classList.add('copy-button');
    button.textContent = 'Copy';
    button.style.position = 'absolute'; // 절대적 위치 설정
    button.style.top = '0'; // 위에 정렬
    button.style.right = '0'; // 오른쪽에 정렬
    button.style.padding = '0.5rem'; // 내부 여백 추가
    button.style.zIndex = '1'; // 다른 요소 위에 표시
    button.addEventListener('click', handleClick);

    // 버튼을 코드 블록 감싸는 div 안에 추가
    wrapper.appendChild(button);
  });
};

// 페이지 로드 및 라우트 업데이트 시에 복사 버튼 추가
export const onRouteUpdate = () => {
  addCopyButtonToCodeBlocks();
};